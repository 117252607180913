<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <img src="@/assets/images/login.png" alt="" />
        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{$t('forgot-password')}} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{$t('Enter your user name and email and we will send you instructions to reset your password')}}
          </b-card-text>

          <!-- form -->
            <g-form
              class="auth-forgot-password-form mt-2"
              @submit="save"
            >
            <g-field
              :value.sync="selectedItem.userName"
              placeholder=""
              label-text="userName"
              rules="required"
              id="login-email"
              name="UserName"
            >
            </g-field>
            <b-form-group>
                <g-field
                :value.sync="selectedItem.email"
                label-text="email"
                rules="email|required"
                />
            </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{$t('forgot-password')}}
              </b-button>
            </g-form>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v2'}">
              <feather-icon icon="ChevronLeftIcon" /> {{$t('backToLogin')}}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
BRow,
BCol,
BLink,
BCardTitle,
BCardText,
BImg,
BFormGroup,
BButton
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    BFormGroup,
    BCardTitle,
    BCardText
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      selectedItem: {},
      updatedUserName: '',
    }
  },
  mounted() {
    this.checkSympole = this.userName.indexOf('\\');
    this.prodctionUrl = document.location.host.indexOf('eduprosys.net');
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    beforeLoginValidation() {
    this.checkSympole = this.selectedItem.userName.indexOf('\\');
    this.prodctionUrl = document.location.host.indexOf('eduprosys.net');
      if (this.checkSympole !== -1 && this.prodctionUrl !== -1) {
        this.doneAlert({
            title: this.$t('notAllowToAddThisSyempol'),
            type: 'error',
          });
        return false;
      }
      return true;
    },
    checkUserName() {
      const check = this.selectedItem.userName.indexOf('\\');
      const prodction = document.location.host.indexOf('eduprosys.net');
      const hostName = document.location.host.split('.')
      const companyName = hostName[0]
      if (check === -1 && prodction !== -1) {
        this.updatedUserName = `${companyName}\\${this.selectedItem.userName}`;
      } else if (check !== -1 && prodction !== -1) {
        this.doneAlert({
            title: this.$t('notAllowToAddThisSyempol'),
            type: 'error',
          });
      } else {
        this.updatedUserName = this.selectedItem.userName
      }
    },
    save() {
      if (!this.beforeLoginValidation()) return;
      this.checkUserName();
      this.create({ url: 'users/reset-password', data: { userName: this.updatedUserName, email: this.selectedItem.email } }).then(() => {
        this.doneAlert({
          text: this.$t('mailWithNewPasswordIsSend'),
        });
        this.$router.push({ name: 'login' });
      });
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
